function datePickerConfig() {
    $('.datepicker').datepicker({
        format: 'mm/dd/yyyy',
        startDate: '-3d'
    });
}
function addChildren() {
    $('#childrenAdd').on('click', function(){
        let el = '<li><div class="row member-child"><div class="col-12 col-md-6"><div class="form-group"><label class="mb-2">Name</label><input type="text" class="form-control" name="spouse-name" id="spouse-name" placeholder="Name" autcomplete="false" /></div></div><div class="col-12 col-md-6"><div class="form-group"><label class="mb-2">Date of Birth</label><div class="input-group date" data-provide="datepicker"><input type="date" class="form-control" name="spouse-dob" id="spouse-dob" placeholder="Date of birth" autcomplete="false" /><div class="input-group-addon"><span class="glyphicon glyphicon-th"></span></div></div></div></div></div></li>';
        $('.children-list').append(el);
    })
}