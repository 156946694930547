function curtainRaiser() {
  const element = $(".curtain-raiser");
  if (element.length) {
    const fromTime = new Date(element.data("from"));
    const endTime = new Date(element.data("to"));
    const current = new Date().getTime();

    if (current - fromTime > 0) {
      element.removeClass("d-none");
      var counter = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = endTime - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        $(".curtain-raiser__right-timer").html(
          days + " : " + hours + " : " + minutes + " : " + seconds
        );

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(counter);
          element.remove();
        }
      }, 1000);
    } else {
      element.addClass("d-none");
      // element.remove();
    }
  }
}
