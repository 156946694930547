(function ($) {
  $(document).ready(() => {
    loader();
    searchToggle();
    stickyMenu();
    timelineAnimation();
    smoothScroll();
    lightBox();
    navMenuAction();
    newsLetterSlider();
    gallery();
    videoPlayers();
    userSearch();
    imgUploader();
    datePickerConfig();
    addChildren();
    passwordToggle();
    curtainRaiser();
  });
})(jQuery);
