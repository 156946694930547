function navMenuAction() {
    let current = location.pathname;
    $('#mainNavbar .nav-item a').each(function(){
        var $this = $(this);
        if($this.attr('href').indexOf(current) !== -1 && current.length>2){
            $this.addClass('active');
            $this.parents('.nav-item').addClass('active');
            $this.parents('.sub-menu').addClass('active');
        }
    })
}