function lightBox() {
  if ($(".lightbox").length) {
    var slideIndex = 1;

    function showSlides(n) {
      var i;
      var slides = document.getElementsByClassName(
        "lightbox__modal-content-slides"
      );
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      slides[slideIndex - 1].style.display = "block";
    }

    showSlides(slideIndex);

    $(".lightbox__item").on("click", function () {
      let currentSlide = $(this).data("current");
      let modalName = $(this).data("targetModal");
      $(modalName).show();
      showSlides((slideIndex = currentSlide));
    });

    $(".lightbox__modal-content-prev").on("click", function () {
      showSlides((slideIndex += -1));
    });

    $(".lightbox__modal-content-next").on("click", function () {
      showSlides((slideIndex += 1));
    });

    $(".lightbox__modal-close").on("click", function () {
      $(this).parent().parent().parent().hide();
    });
  }
}
