if (typeof controller === "undefined") {
  var controller = new ScrollMagic.Controller();
}

$('[data-appear="from-left"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let offset =
    $(this).data("offset") !== undefined ? $(this).data("offset") : 100;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { x: -offset, ease: Linear.ease })
    .from($(this), 1, { autoAlpha: 0, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});

$('[data-appear="from-right"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let offset =
    $(this).data("offset") !== undefined ? $(this).data("offset") : 100;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { x: offset, ease: Linear.ease })
    .from($(this), 1, { autoAlpha: 0, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});

$('[data-appear="from-top"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let offset =
    $(this).data("offset") !== undefined ? $(this).data("offset") : 100;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { y: -offset, ease: Linear.ease })
    .from($(this), 1, { autoAlpha: 0, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});

$('[data-appear="from-bottom"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let offset =
    $(this).data("offset") !== undefined ? $(this).data("offset") : 100;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { y: offset, ease: Linear.ease })
    .from($(this), 1, { autoAlpha: 0, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});

$('[data-appear="fade"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { opacity: 0, ease: Linear.ease })
    .to($(this), 1, { opacity: 1, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});

$('[data-appear="scale-outer"]').each(function () {
  let delay = $(this).data("delay") !== undefined ? $(this).data("delay") : 0.5;
  let tween = new TimelineMax()
    .delay(delay)
    .from($(this), 1, { scale: 0.95, opacity: 0, ease: Linear.ease })
    .to($(this), 1, { scale: 1, opacity: 1, ease: Linear.ease }, "-=1");
  new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(tween)
    .addTo(controller);
});
