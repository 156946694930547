function videoPlayers() {
  // const players = [...$(".video-js")];
  // if (players.length) {
  //   players.forEach(function (player) {
  //     player.on("play", function () {
  //       players.forEach(function (pl) {
  //         if (pl !== player) {
  //           pl.pause();
  //         }
  //       });
  //     });
  //   });
  // }
}
