function newsLetterSlider() {
  if ($(".slider-nav").length) {
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav",
    });
    $(".slider-nav").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      dots: false,
      centerMode: true,
      focusOnSelect: true,
      prevArrow:
        "<div class='slider__nav slider__nav_prev'><i class='ic-circle-left'></i></div>",
      nextArrow:
        "<div class='slider__nav slider__nav_next'><i class='ic-circle-right'></i></div>",
    });
  }
}
