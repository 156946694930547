function userSearch() {
  if ($(".content-letter").length) {
    let allElms = $(".content-letter__btn");
    $(".content-letter__btn").on("click", function () {
      for (let i = 0; i < allElms.length; i++) {
        $(allElms[i]).removeClass("content-letter__btn_active");
      }
      $(this).addClass("content-letter__btn_active");
    });
  }
}
