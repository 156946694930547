function stickyMenu() {
  $(window).on("scroll", () => {
    if ($(window).scrollTop() > 0) {
      $(".navbar").addClass("fixed-top");
    }
    if ($(window).scrollTop() < 1) {
      $(".navbar").removeClass("fixed-top");
    }
  });
  
  $('#mainNavbar').on('show.bs.collapse',  () => {
    $(".header").addClass("menu-open");
  })
    
  $('#mainNavbar').on('hide.bs.collapse',  () => {
    setTimeout(function() {
      $(".header").removeClass("menu-open");
    }, 500);
  })

  // $('.dropdown-item').on('click', (e) => {
  //   $('.dropdown-item').removeClass('active').closest('.dropdown').find('.nav-link').removeClass('active');
  //   $(this).addClass('active').closest('.dropdown').find('.nav-link').addClass('active');
  // });
  // $('.nav-link').on('click', () => {
  //     $('.nav-link').removeClass('active');
  //     $(this).addClass('active')
  // });
}
