function gallery() {
  function slickCarousel(forCarousel, navCarousel, modal) {
    forCarousel.not(".slick-initialized").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      swipeToSlide: true,
      fade: true,
      asNavFor: modal + " .gallery-modal__slider-nav",
      prevArrow:
        "<div class='gallery-modal__nav gallery-modal__nav_prev'><i class='ic-circle-left'></i></div>",
      nextArrow:
        "<div class='gallery-modal__nav gallery-modal__nav_next'><i class='ic-circle-right'></i></div>",
    });
    navCarousel.not(".slick-initialized").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: modal + " .gallery-modal__slider-for",
      dots: false,
      centerMode: true,
      arrows: false,
      swipeToSlide: true,
      focusOnSelect: true,
    });

    let next = $(".gallery-modal__nav_next");
    let prev = $(".gallery-modal__nav_prev");
    prev.addClass("disabled");

    navCarousel.on("afterChange", function (event, slick, currentSlide) {
      if (currentSlide + 1 === slick.slideCount) {
        next.addClass("disabled");
      } else if (currentSlide === 0) {
        prev.addClass("disabled");
      } else {
        next.removeClass("disabled");
        prev.removeClass("disabled");
      }
    });

    if ($(".modal-content").hasClass("modal-loader")) {
      setTimeout(function () {
        $(".modal-content").removeClass("modal-loader");
      }, 1000);
      if (!$(".modal-content").hasClass("modal-loader")) {
        $(".modal-content").addClass("modal-loader");
      }
    }
  }

  function destroyCarousel(forCarousel, navCarousel) {
    if (navCarousel.hasClass("slick-initialized")) {
      forCarousel.slick("unslick");
      navCarousel.slick("unslick");
    }
  }

  $(".gallery__card").on("click", function () {
    let modal = $(this).data("target");
    let forCarousel = $(modal + " .gallery-modal__slider-for");
    let navCarousel = $(modal + " .gallery-modal__slider-nav");
    $(modal).modal("show");
    $(".gallery-photo").on("shown.bs.modal", function (e) {
      destroyCarousel(forCarousel, navCarousel, modal);
      slickCarousel(forCarousel, navCarousel, modal);
    });
  });
}
