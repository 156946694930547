function timelineAnimation() {
  if (typeof controller === "undefined") {
    var controller = new ScrollMagic.Controller();
  }
  let timelineElements = $(".timeline .timeline__card");
  timelineElements.each((index, element) => {
    new ScrollMagic.Scene({
      triggerElement: element,
      triggerHook: 1,
    })
      .on("enter", (event) => {
        $(element).addClass("fadeInUp");
      })
      .on("leave", (event) => {
        $(element).removeClass("fadeInUp");
      })
      .addTo(controller);
  });
}
