// Breakpoints
let $gridBreakpoints = {
    xs: '0',
    sm: 576,
    // md: 768,
    md: 778,
    lg: 992,
    xl: 1200
}

// window
const $win = $(window);

// header
var $header = $(".app-header");
var $body = $('body');

// page offset margin
var $pageOffsetX = 80;

