function passwordToggle(){
    $('.eye-toggle-icon').on('click', function(){
        if($(this).hasClass('ic-eye')){           
            $(this).removeClass('ic-eye');            
            $(this).addClass('ic-eye-blocked');      
            $(this).closest('.password-group').find('.form-control').attr('type','text');       
        } else{
            $(this).removeClass('ic-eye-blocked');            
            $(this).addClass('ic-eye');           
            $(this).closest('.password-group').find('.form-control').attr('type','password');  
        }
    })
}