function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $(".img-uploader__preview img").attr("src", e.target.result);
      $(".img-uploader__label").text(input.files[0].name);
    };
    reader.readAsDataURL(input.files[0]);
  }
}

function imgUploader() {
  $(".img-uploader__input").on("change", function (event) {
    readURL(this);
  });
}
